import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import Video from '../../components/video.js';
import '../../styles/article.scss';
import '../../styles/case-study.scss';
import Contact from '../../components/contact.js';

const CaseStudy = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="Russell & Atwell: A Shopify Case Study" 
              description="How 54 Bit Studios Helped Russell & Atwell
Elevate Their Website for Faster Speed, Improved
Efficiency, and a Seamless Customer Experience."
              image="../assets/video/testimonial-poster.jpg"
              article={true}/>
            <PageHeading h1="Russell & Atwell: A Shopify Case Study" h2="Case Study" blurb="How 54 Bit Studios Helped Russell & Atwell
Elevate Their Website for Faster Speed, Improved
Efficiency, and a Seamless Customer Experience." />
            <GatsbyImage image={data.chocolates.childImageSharp.gatsbyImageData} alt="Russell & Atwell chocolate" />
            <section className="ff article">
                <h3>Overview</h3>
                <p>Russell and Atwell is an innovative chocolate company founded by Giles Atwell and Steve Russell, who collectively have nearly 40 years of experience with world-renowned chocolate brands. “We broke away from much more sensible corporate careers in 2019 and started our own business making fresh chocolate,” shared Giles. Officially launched in October 2020, Russell and Atwell has been dedicated to producing high-quality chocolate products ever since.</p>
                <p>After initial challenges managing Shopify independently, Russell & Atwell turned to 54 Bit Studios — recommended by a trusted industry contact — to help them optimise and refresh their ecommerce site. The partnership’s primary objective was to refresh the site and upgrade from Shopify 1.0 to 2.0, improving loading speed and enhancing the user experience.</p>
                <h3>Context</h3>
                <p>As a small business with time and budget constraints, Russell & Atwell faced several ecommerce challenges that required tailored solutions. Key obstacles included:</p>
                <ul className="numbered">
                    <li><strong>Website Performance and Speed:</strong> Built on Shopify 1.0, the existing site’s slower speed was affecting customer experience and required faster load times to effectively support customer retention and conversions.</li>
                    <li><strong>Self-Built Limitations:</strong> Initially built in-house, the site had structural limitations that often required external support to address issues with Shopify apps and integrations.</li>
                    <li><strong>Subscription Management Complexity:</strong> Managing a robust subscription model was a major hurdle for Russell & Atwell’s growing customer base. While 54 Bit Studios initially recommended Shopify’s subscription service, it didn’t meet all the needs. The team quickly pivoted to help transition back to their former system, Recharge, stabilising the subscription experience. As Giles noted, “They worked pretty hard to bring us back and re-stabilise everything.”</li>
                </ul>
                <p>Having worked with 54 Bit Studios previously and seeing the value in their detailed and competitively priced proposal, Russell & Atwell decided to move forward with a site refresh rather than a complete rebuild, focusing on key performance improvements while retaining the core structure.</p>
                <h3>Approach</h3>
                <p>Over the past two years, we have collaborated on several key projects:</p>
                <ul className="numbered">
                    <li><strong>Website Christmas Preparation:</strong> We assisted in preparing the site for the holiday season by installing a custom pre-order button.</li>
                    <li><strong>Website Refresh and Relaunch:</strong> Our most significant project was a comprehensive refresh of the website to ensure a seamless user experience during peak sales periods.</li>
                    <li><strong>Upgrade to Shopify 2.0:</strong> Upgrading to Shopify 2.0 enhanced site performance, providing a faster, more streamlined customer experience. We ensured a seamless transition, preserving the Russell & Atwell branding and UX design while using Shopify 2.0’s advanced features for better speed and functionality.</li>
                </ul>
                <p>Each phase underwent rigorous quality assurance with our team before release, and we implemented a phased release strategy to ensure a smooth transition to the new website. We used SEO best practices throughout the process, which resulted in improved traffic after launch.</p>
                <h3>Communication and Support</h3>
                <p>Giles praised our team’s responsiveness and communication style: “We use Slack and the comms are really good. 54 Bit Studios are very responsive.” Our structured system for feedback and updates facilitated the smooth execution of projects. “We felt like we were in safe hands with them,” he added.</p>
                <GatsbyImage image={data.fridge.childImageSharp.gatsbyImageData} alt="Russell & Atwell chocolates in a fridge" />
            </section>
            <section className="ff article">
                <h3 className="ff">Key Benefits</h3>
                <p>Throughout our partnership, we’ve provided Russell & Atwell with several key benefits:</p>
                <ul className="numbered">
                    <li><strong>Expertise in Shopify:</strong> “They really understand and have proper deep expertise on Shopify,” Giles noted, which has been invaluable for the business.</li>
                    <li><strong>Pragmatic Approach:</strong> Our team effectively navigated the gap between client expectations and practical solutions. “They’re good at thinking their way through problems within the constraints of what the store system operates on,” Giles remarked.</li>
                    <li><strong>Direct Communication:</strong> Direct access to our team members has been a significant advantage. Giles shared “I really like the one-to-one attention that you get with them”.</li>
                </ul>
                <GatsbyImage image={data.website.childImageSharp.gatsbyImageData} alt="Russell & Atwell website" />
                <h3 className="ff">Conclusion</h3>
                <p>Our collaboration with Russell and Atwell has been marked by strong partnership and transparency. As Giles put it, “We try to treat it as a partnership.” The enhancements to their website have strengthened their sales channel and positioned them for future growth. He concluded, “I’m very likely to recommend them... they did a great job for us on the site... We know they work hard. We know that they’re always there to talk through stuff. We felt like we were in safe hands with them.”</p>
                <p>At 54 Bit Studios, we’re proud to have played a role in supporting Russell & Atwell’s growth and success in the chocolate industry.</p>
            </section>
            <Contact heading="Get in touch" subheading="If you need any help getting your store primed and ready for its best weekend ever, let’s chat!" />
        </Layout>
    )
}

export const query = graphql`
  query {
    chocolates: file(relativePath: { eq: "clients/russell-and-atwell/chocolates.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    fridge: file(relativePath: { eq: "clients/russell-and-atwell/fridge.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    website: file(relativePath: { eq: "clients/russell-and-atwell/website.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default CaseStudy